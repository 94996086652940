// 登录页
import Vue from '@vue'
import axios from 'mp_axios'
import i18n from '@miracleplus/vue_i18n'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

let locale = Vue.$cookies.get("locale") == 'en' ? i18n.en : i18n.cn

$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
  const target = $(e.target).attr("href")
  if (target == '#password') {
    $('.js-email-captcha-image').trigger('click');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '[data-phone-token]',
    data: {
      content: locale.session.send_verification_code,
      totalTime: 60,
      can_click: false,
      phone: null,
      captcha: '',
      is_invalid: false,
      show_captcha_error: false,
      error_message: null,
      captcha_error_message: null,
      intl_tel_input: null
    },
    mounted() {
      this.intl_tel_input = window.initIntlTelInput(this.$refs.intlTelInput,Vue.$cookies.get("locale") === 'en' ? "US" : "CN")
    },
    methods: {
      typing() {
        this.clearError();
        this.enableSendBtn();
        this.formatIntlTelInput();
      },

      validate() {
        this.formatIntlTelInput()
        var phone = this.phone;
        var result = this.validatePhone(phone);
        if (result.isValid) {
          this.clearError();
          this.enableSendBtn();
        } else {
          this.showError(result.message);
          this.disableSendBtn();
        }
      },

      formatIntlTelInput() {
        // console.log(e);
        if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
          var currentText = this.intl_tel_input.getNumber(intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === 'string') { // sometimes the currentText is an object :)
            // console.log(currentText)
            this.intl_tel_input.setNumber(currentText); // will autoformat because of formatOnDisplay=true
            this.phone = this.$refs.intlTelInput.value
          }
        }
      },

      verifyTokenCaptcha() {
        if (this.can_click === false) { return }

        this.validate()
        if(this.is_invalid === true ) { return }

        $('.js-phone-captcha-image').trigger('click');
        $('#captcha-confirm').modal('show');

        // model 显示时，切换焦点到验证码输入框中，以免原焦点在原来的“发送验证码”按钮上，回车会刷验证码
        setTimeout(() => {
          $(".graphic_captcha").trigger('focus');
        },500)
      },

      sendToken(request_token_url, event) {
        event.preventDefault();
        var rawPhone = (this.phone || '').replace(/[^0-9+]/g, ''); // 只保留数字和加号
        this.phone = rawPhone; // 更新文本框的值

        var that = this;
        axios.post(request_token_url, {
          phone: this.phone,
          _rucaptcha: this.captcha
        }).then(function (response) {
          var data = response.data;
          if (data.status == 'ok') {
            $('#captcha-confirm').modal('hide');
            that.show_captcha_error = false;
            that.disableSendBtn();
            that.content = locale.session.resend_code_tips.replace("{seconds}", that.totalTime)
            let clock = window.setInterval(() => {
              that.totalTime--
              that.content = locale.session.resend_code_tips.replace("{seconds}", that.totalTime)
              if (that.totalTime <= 0) {
                window.clearInterval(clock)
                that.content = locale.session.resend_verification_code
                that.totalTime = 60
                that.enableSendBtn();
              }
            }, 1000)
          } else if (data.status == 'captcha_error') {
            $('.js-phone-captcha-image').trigger('click');
            that.captcha = '';
            that.showCaptchaMessage(data.message);
          } else {
            $('#captcha-confirm').modal('hide');
            that.showError(data.message);
          }
        })
      },

      showCaptchaMessage(message){
        this.captcha_error_message = message
        this.show_captcha_error = true
      },

      showError(message) {
        this.is_invalid = true;
        this.error_message = message;
      },
      clearError() {
        this.is_invalid = false;
        this.error_message = '';
      },
      enableSendBtn() {
        this.can_click = true;
      },
      disableSendBtn() {
        this.can_click = false;
      },
      validatePhone(phone) {
        var phoneNumber = (phone || '').replace(/\s+/g, ""); // 删除所有空格
        var errMessage = '';

        // 电话号码为空
        if (!phoneNumber) {
          errMessage = locale.session.error_for_empty;

        // 如果是中国的号码，进一步检查一下格式
        } else if(phoneNumber.startsWith('+86') && !(/^(?:\+?86)?1\d{10}$/.test(phoneNumber))) {
          errMessage = locale.session.error_for_not_valid;
        }

        return {
          isValid: !Boolean(errMessage),
          message: errMessage
        }
      }
    }
  })
})
