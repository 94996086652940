export default {
  en: {
    picture: 'Picture',
    upload_progress: 'Upload progress',
    upload_completed: 'The upload is complete',
    new: 'New',
    edit: 'Edit',
    delete: 'Delete',
    actions: 'Actions',
    submit: 'Submit',
    preview: 'Preview',
    display: 'Display',
    hidden: 'Hidden',
    prev: 'Prev',
    next: 'Next',
    one_pager: 'One Pager',
    title: 'Title',
    desc: 'Description',
    created_at: 'Created At',
    current_version: 'Current version',
    display_effect: 'Visual Effect',
    access_account: 'Access Account',
    access_account_desc: 'The function of "access account" is to log in mini program as MiraclePlus alumni',
    instruction: 'Instruction',
    instruction_step_1: ' Open "MiraclePlus WeChat Mini Program"',
    instruction_step_2: 'Select your identity as "MiraclePlus Alumni" (three options in total: Founder, MiraclePlus Alumni, Investor)',
    instruction_step_3: 'Fill in your phone number+ registration code',
    name: 'Name',
    phone: 'Phone',
    registration_code: 'Registration code',
    status: 'Status',
    access_account_delete_confirm: 'Confirm to delete access account?',
    one_pager_delete_confirm: 'Confirm to delete one pager?',
    submit_success: 'The submission was successful',
    submit_failed: 'Submission failed',
    save_as_picture: 'Download one-pager as picture',
    save_as_doc: 'Download one-pager as Word document',
    contact: 'Contact',
    example: 'Example',
    quick_jump: 'Quick Jump',
    one_sentence_description: 'One Sentence Description',
    for_example: 'For example',
    desc_example: 'For example: Provide data support and related analysis for the full commercial link of cross-border e-commerce sellers',
    key_points_list: 'Key Points',
    add_key_point: 'Add Key Point',
    sections: 'Sections',
    section: 'Section',
    add_section: 'Add Section',
    section_title: 'Section Title',
    section_content: 'Section content (please start with "#" for subheadings, e.g. "# Zhang San: CEO")',
    like_wx_qrcode: 'Like WeChat QR code',
    choose_file: 'Click here to select file',
    file_size_limit: 'The file size should be less than 2MB',
    display_tip: 'The visuals here could be slightly different from final display on mini program',
    action_cannot_revoke: 'This action cannot be revoked',
    confirm_delete_picture: 'Confirm to delete the picture',
    title_cannot_be_blank: 'The title cannot be blank',
    desc_cannot_be_blank: 'The description cannot be blank'
  },
  cn: {
    picture: '图片',
    upload_progress: '上传进度',
    upload_completed: '上传完成',
    new: '新建',
    edit: '编辑',
    delete: '删除',
    actions: '操作',
    submit: '提交',
    preview: '预览',
    display: '显示',
    hidden: '隐藏',
    prev: '上一个',
    next: '下一个',
    one_pager: '一页纸',
    title: '标题',
    desc: '描述',
    created_at: '创建时间',
    current_version: '此版本',
    display_effect: '对外显示',
    access_account: '访问账号',
    access_account_desc: '"访问账号"的用途是以"校友"身份登录小程序。',
    instruction: '使用方法',
    instruction_step_1: '打开"奇绩微信小程序"',
    instruction_step_2: '身份选择"奇绩校友"（身份选择共3个选项:创业者", "奇绩校友", "投资人"）',
    instruction_step_3: '填入手机号+注册码',
    name: '名字',
    phone: '手机号',
    registration_code: '注册码',
    status: '状态',
    access_account_delete_confirm: '确认删除访问账号?',
    one_pager_delete_confirm: '确认删除一页纸?',
    submit_success: '提交成功',
    submit_failed: '提交失败',
    save_as_picture: '下载一页纸为图片',
    save_as_doc: '下载一页纸为 word 文档',
    contact: '联系方式',
    example: '例子',
    quick_jump: '快速跳转',
    one_sentence_description: '一句话描述',
    desc_example: '比如: 为跨境电商卖家全商业链路提供数据支持及相关分析',
    for_example: '比如',
    key_points_list: '关键点列表',
    add_key_point: '添加关键点',
    sections: '章节列表',
    section: '章节',
    add_section: '添加章节',
    section_title: '章节标题',
    section_content: '章节内容 (小标题请以 "#" 开头，比如 "# 张三: CEO")',
    like_wx_qrcode: '比如微信二维码图片',
    choose_file: '点击此处开始选择文件',
    file_size_limit: '图片文件必须小于 2M',
    display_tip: '最终显示效果以小程序为准, 此处预览可能有微小差异',
    action_cannot_revoke: '此操作无法撤销',
    confirm_delete_picture: '确认删除图片',
    title_cannot_be_blank: '标题不能为空',
    desc_cannot_be_blank: '描述不能为空'
  }
}
