export default {
  en: {
    time: 'Time',
    start_time: 'Start Time',
    end_time: 'End Time',
    link: 'Link',
    category: 'Category',
    ture: 'True',
    false: 'False',
    setting: 'Setting',
    desc: 'Description',
    search: 'Search',
    name: 'Name',
    title: 'Title',
    content: "Content",
    confirm: 'Confirm',
    cancel: 'Cancel',
    add: 'Add {name}',
    create: 'Create',
    edit: 'Edit',
    show: 'Show',
    edit_with_name: 'Edit {name}',
    delete: 'Delete',
    actions: 'Actions',
    open_document: 'Open document',
    set_privacy: 'Make it private',
    set_privacy_tip: 'Are you sure to Make it private?',
    cancel_privacy: 'Cancel privacy',
    cancel_privacy_tip: 'Are you sure to cancel private?',
    directory: 'Directory',
    parent_directory: 'Parent directory',
    handbook: 'Handbook',
    batch: 'Batch',
    group: 'Group',
    calendar: 'Calendar',
    template: 'Template',
    group_name: 'Group Name',
    company: 'Company',
    company_name: 'Company Name',
    companies_in_group: 'Companies In Group',
    associate_directory: 'Associate Directory',
    associate_directory_tip: 'Only unpublished handbook catalogs',
    is_public: 'Is public',
    is_public_tip: 'Public: Visible to all founders, not public: Members of the group after association in the company group are visible',
    delete_tip: 'Are you sure to delete the {name} ?',
    success: 'Operation Successed!',
    failed: 'Operation failed !',
    fillin_tip: 'Please fill in {name}',
    select_tip: 'Please select {name}',
    not_empty_msg: '{name} can not empty',
    max_length_msg: 'Up to {size} characters are supported',
    public_task: 'Public Assignment',
    internal_task: 'Internal Assignment',
    internal_material: 'Internal Material',
    public_task_template: 'Public Assignment Template',
    internal_task_template: 'Internal Assignment Template',
    internal_material_template: 'Internal Material Template',
    internal_material_link: 'Internal Material Link',
    open_template: 'Open template',
    assignment_list: 'Assignment List',
    schedule: 'Schedule',
    supplementary_sending: 'Supplementary Send',
    supplementary_sending_tip: "Are you sure to supplementary send ?",
    assignment_link: "Assignment Link",
    assignment_name: 'Assignment Name',
    ddl_time: "Local Time (DDL)",
    template_category: 'Template Category',
    task_category: 'Assignment Category',
    public: 'Public',
    internal: 'Internal',
    template_change_tip: 'The template has changed, so proceed with caution',
    schedule_info: 'Assignment Info',
    open_handbook: 'Open Handbook',
    related_handbook: 'Related Handbook',
    send_message: 'Send Message',
    sync_to_lark: 'Sync To Lark',
    open_edit_permission: 'open editing permission',
    close_edit_permission: 'close editing permission',
    open_edit_permission_tip: 'Are you sure to open editing permission ?',
    close_edit_permission_tip: 'Are you sure to close editing permission ?',
    last_update_time: 'last updatd time',
    empty: 'Empty',
    send_msg_to_lark: 'Send a message to the lark group',
    group_robot: 'Group(robot)',
    link_text: 'Link text',
    all: 'All',
    position: 'Sort Position (The smaller the number, the closer to the front)',
    folder_id: 'Folder',
    todo_list: {
      add_title: 'Add Todo',
      edit_title: 'Edit Todo',
      title: 'Todo List',
      add: 'Add Todo',
      edit: 'Edit',
      delete: 'Delete',
      deadline: 'DDL Time',
      mark_done: 'Done',
      description: 'Description',
      closed_at: 'Closed At',
      todo: 'Todo',
    }
  },
  cn: {
    time: '时间',
    start_time: '开始时间',
    end_time: '结束时间',
    link: '链接',
    category: '类型',
    true: '是',
    false: '否',
    setting: '设置',
    desc: '描述',
    search: '搜索',
    name: '名称',
    title: '标题',
    content: "内容",
    confirm: '确定',
    cancel: '取消',
    add: '添加{name}',
    create: '创建',
    edit: '编辑',
    show: '查看',
    edit_with_name: '编辑{name}',
    delete: '删除',
    actions: '操作',
    open_document: '打开文档',
    set_privacy: '设为私密',
    set_privacy_tip: '确定要设置为私密吗?',
    cancel_privacy: '取消私密设置',
    cancel_privacy_tip: '确定要取消私密设置吗?',
    directory: '目录',
    parent_directory: '上级目录',
    handbook: '手册',
    batch: '批次',
    group: '分组',
    calendar: '日历',
    template: '模板',
    group_name: '分组名称',
    company: '公司',
    company_name: '公司名称',
    companies_in_group: '组内公司',
    associate_directory: '关联目录',
    associate_directory_tip: '仅未公开的资料手册目录',
    is_public: '是否公开',
    is_public_tip: '公开：所有创始人都可见，不公开: 在公司分组中关联后分组的成员可见',
    delete_tip: '确定要删除当前{name}吗?',
    cancel_tip: '确定要取消当前{name}吗?',
    success: '操作成功',
    failed: '操作失败',
    fillin_tip: '请填写{name}',
    select_tip: '请选择{name}',
    not_empty_msg: '{name}不能为空',
    max_length_msg: '最多支持{size}个字符',
    public_task: '公开作业',
    internal_task: '内部作业',
    internal_material: '内部材料',
    public_task_template: '公开作业模板',
    internal_task_template: '内部作业模板',
    internal_material_template: '内部材料模板',
    internal_material_link: '内部材料链接',
    open_template: '打开模板',
    assignment_list: '作业汇总',
    schedule: '日程',
    supplementary_sending: '补发',
    supplementary_sending_tip: "确定要补发当前内部材料吗?",
    assignment_link: "作业链接",
    assignment_name: '作业名称',
    ddl_time: "时间安排/DDL",
    template_category: '模板类型',
    task_category: '作业类型',
    public: '公开',
    internal: '内部',
    template_change_tip: '作业模板发生了变化，请谨慎操作',
    schedule_info: '日程信息',
    open_handbook: '打开手册',
    related_handbook: '相关手册',
    send_message: '发送消息通知',
    sync_to_lark: '同步到飞书',
    open_edit_permission: '打开编辑权限',
    close_edit_permission: '关闭编辑权限',
    open_edit_permission_tip: '确定要打开编辑权限吗？',
    close_edit_permission_tip: '确定要关闭编辑权限吗?',
    last_update_time: '最后更新时间',
    empty: '暂无',
    send_msg_to_lark: '发送消息到飞书群组',
    group_robot: '群组(机器人)',
    link_text: '显示文字',
    all: '全体成员',
    position: "排序位置 (越小越靠前)",
    folder_id: '目录',
    todo_list: {
      add_title: '添加 Todo',
      edit_title: '编辑 Todo',
      title: 'Todo List',
      add: '添加 Todo',
      edit: '编辑',
      delete: '删除',
      deadline: '截止时间',
      mark_done: '完成',
      description: '描述',
      closed_at: '结束时间',
      batch: '批次',
      todo: 'Todo'
    }
  }
}
